//
// Tables
//

@import "../core/tables/table";

.table-content-icon {
  font-size: 22px;

  &.ni-fat-remove {
    font-size: 25px;
  }
}

.card .table {
  td,
  th {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
