//
// Forms
//

@import "../core/forms/form";
@import "../core/forms/form-validation";
@import "../core/forms/input-group";

.form-group label {
  color: $text-muted;
}
