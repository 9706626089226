//
// Dropzone
//

.dropzone-wrapper {
  display: block;

  .label {
    font-size: 13px;
    margin-bottom: 0.2rem;
  }
  .dropzone {
    border: 1px solid $gray-400;
    border-radius: 0.375rem;
    cursor: pointer;
    height: calc(2.2rem + 2px);

    &.error {
      border-color: $red;
    }
    .file-name-wrapper {
      align-items: center;
      display: flex;
      height: 100%;
      padding: 0 0.75rem;

      .file-name {
        color: $gray-600;
        display: block;
        flex: 1;
        font-size: 15px;
        line-height: 2.2rem;
        overflow: hidden;
        padding-right: 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .fas {
        color: $gray-600;
      }
    }
  }
}
