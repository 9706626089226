//
// Navbar
//

@import "../core/navbars/navbar";
@import "../core/navbars/navbar-vertical";
@import "../core/navbars/navbar-search";
@import "../core/navbars/navbar-dropdown";
@import "../core/navbars/navbar-collapse";

.navbar-brand {
  border-bottom: 1px solid $gray-300;
}
.navbar-vertical .navbar-collapse:before {
  margin-top: 0;
}
#navbar-main {
  .form-header {
    display: flex;
    align-items: center;

    .breadcrumb-back {
      border: 2px solid $secondary;
      border-radius: 50%;
      color: $secondary;
      font-size: 11px;
      height: 20px;
      margin-top: 2px;
      text-align: center;
      width: 20px;
    }
    .breadcrumb {
      background-color: transparent;
      margin-bottom: 0;

      li {
        color: white;

        &:before {
          color: $secondary;
        }
        a {
          color: $secondary;
        }
      }
    }
  }
}
