//
// Nav
//

@import "../core/navs/nav";
@import "../core/navs/nav-pills";

[class^="styles_loading-bar_"] {
  background-color: $primary !important;
}

#navbar-main .form-header .breadcrumb-back,
#navbar-main .form-header .breadcrumb li a,
#navbar-main .form-header .breadcrumb li:before {
  border-color: $text-muted !important;
  color: $text-muted !important;
}

.nav {
  &.nav-tabs {
    background-color: $body-bg;
    border-bottom: 1px solid $gray-300;
    color: $text-muted;
    margin-left: -25px;
    margin-right: -25px;
    margin-top: -25px;

    .nav-link {
      border-radius: 0;
      cursor: pointer;
      font-size: 14px;
      padding: 9px 15px 7px;

      &.active {
        background-color: white;
        border-bottom: 2px solid white;
        border-top: 0;
      }
    }
  }
}
.tab-content {
  padding-top: 15px;
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: $primary;
}

.navbar-light .navbar-nav .nav-link {
  color: $text-muted;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: white;
}

.navbar-light .navbar-nav .nav-link:focus {
  color: $primary;
}
