//
// Modal
//

@import "../core/modals/modal";

.modal-body {
  max-height: calc(100vh - 270px);
  overflow: auto;
}
