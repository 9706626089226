//
// Button
//

@import "../core/buttons/button";
@import "../core/buttons/button-icon";
@import "../core/buttons/button-brand";

.btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;
}

.save-button {
  min-width: 100px;
}

.add-button {
  i {
    font-size: 15px;
    padding-right: 5px;
    transform: translateY(3.5px);
  }
}

.contract-selection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  label {
    font-size: 13px;
  }
}
.button-choosable {
  background-color: $gray-200;
  border: 1px solid $gray-200;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem 1rem;
  position: relative;
  width: calc(50% - 5px);

  &:hover {
    border-color: $secondary;
  }
  &.selected {
    background-color: white;
    border: 1px solid $secondary;

    &:before {
      display: none;
    }
  }
  &:before {
    border: 2px solid $gray-500;
    border-radius: 50%;
    content: "";
    display: block;
    flex-shrink: 0;
    height: 15px;
    margin-top: 0.3rem;
    position: absolute;
    width: 15px;
  }
  .fas {
    color: $secondary;
    flex-shrink: 0;
    font-size: 15px;
    margin-top: 0.3rem;
    position: absolute;
  }
}

.cursor-pointer {
  cursor: pointer;
}
