//
// Input group
//

@import "../core/forms/input-group";

.form-group {
  margin-bottom: 0.5rem;

  label {
    font-size: 13px;
    margin-bottom: 0.2rem;
  }
  .form-control {
    font-size: 15px;
    height: calc(2.2rem + 2px);

    &:focus {
      border-color: $secondary;
      box-shadow: none;
    }
  }
}
.form-select {
  &.error {
    .select-form__control {
      border-color: $red !important;
    }
  }
  .select-form__control {
    height: calc(2.2rem + 2px);

    &.select-form__control--is-focused {
      border-color: $secondary !important;
      box-shadow: none;
    }
    .select-form__placeholder,
    .select-form__single-value {
      color: $gray-600;
    }
    .select-form__single-value {
      font-size: 15px;
    }
  }
  .select-form__option {
    color: #333;

    &:focus,
    &:focus-within,
    &:hover,
    &:active,
    &.select-form__option--is-focused {
      background-color: $gray-400;
    }
  }
  .select-form__option--is-selected {
    background: $primary !important;
  }
}

[class^="RichTextEditor__root"] {
  color: $gray-800;
  position: relative;
  z-index: 0;

  .DraftEditor-root {
    font-family: Open Sans, sans-serif;
    font-size: 13px;
    height: 260px !important;
  }
}

.form-select .select-form__control {
  height: auto;
  min-height: calc(2.2rem + 2px);
}

.hide-input-number-controls {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.color-input {
  position: relative;
  z-index: 1;

  .color-input-colorpicker {
    position: absolute;
    right: 15px;
    top: 65px;
  }
}

.react-date-picker__inputGroup__divider {
  color: grey;
}

.react-calendar {
  background-color: $secondary !important;
}

.react-calendar__tile--active {
  background-color: $primary !important;
}

.react-calendar__tile--hover {
  background-color: $default !important;
}

.react-calendar__tile--hasActive {
  background: #7ffdaf !important;
}

.react-calendar__navigation__arrow,
.react-calendar__navigation__label,
.react-calendar__tile {
  color: white !important;
}
