//
// Alert
//

@import "../core/alerts/alert";
@import "../core/alerts/alert-dismissible";
// @import "react-confirm-alert/src/react-confirm-alert.css";

.react-confirm-alert {
  .react-confirm-alert-body {
    text-align: center;

    h1 {
      line-height: 1.8rem;
    }
    .react-confirm-alert-button-group {
      justify-content: center;

      button {
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
  }
}
