//
// Collapsible
//

.Collapsible {
  border: 1px solid $hr-border-color;
  border-radius: 3px;
  margin-bottom: 0.5rem;

  .Collapsible__trigger {
    align-items: center;
    background-color: $gray-100;
    border-bottom: 1px solid transparent;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    justify-content: space-between;
    padding: 0.5rem;

    &.is-open {
      border-bottom: 1px solid $hr-border-color;

      .fas:before {
        content: "\F077";
      }
    }
  }
  .Collapsible__contentInner {
    padding: 0.5rem;
  }
}
