iframe {
    border: 0;
}

figcaption,
figure,
main {
    display: block;
}

main {
    overflow: hidden;
}

//=====================================
//  SCROLL
//=====================================
html{
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        background-color: #ededed;
        width: 10px;
        height: 10px;
    }
    ::-webkit-scrollbar-thumb {
        border: 1px solid #ededed;
        background-color: $primary;
    }
}